@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --max-width: 1100px;
  --border-radius: 12px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  min-height: 100vh;
  height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: var(--font-bj), system-ui, sans-serif;
}

body {
  color: #e5e7eb;
  background-color: #000000;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.layout-background-image {
  background-image: radial-gradient(circle at top center, #526e9710, transparent), url('/shared/diag-ascii.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  background-clip: border-box;
}

@media (max-width: 1000px) {
  .layout-background-image {
    background-position: left top;
  }
}

@media (min-width: 1000px) and (max-width: 1300px) {
  .layout-background-image {
    background-size: 125%;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .layout-background-image {
    background-size: 150%;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .layout-background-image {
    background-size: 200%;
  }
}

@media (max-width: 600px) {
  .layout-background-image {
    background-size: 250%;
  }
}

.gradient-box {
  background: radial-gradient(
      232.36% 214.3% at 124.11% -92.78%,
      rgba(218, 227, 242, 0.2) 0%,
      rgba(102, 122, 155, 0) 100%
    ),
    #1c202960;
}

.gradient-box-dark {
  background: radial-gradient(
      232.36% 214.3% at 124.11% -92.78%,
      rgba(218, 227, 242, 0.2) 0%,
      rgba(102, 122, 155, 0) 100%
    ),
    #00000060;
}

.selected-nav-item {
  background: radial-gradient(49.93% 24.26% at 50% 100%, rgba(136, 180, 245, 0.32) 0%, rgba(136, 180, 245, 0) 100%);
}
.selected-nav-item-mobile {
  background: radial-gradient(7.97% 45.33% at 0% 50%, rgba(136, 180, 245, 0.32) 0%, rgba(136, 180, 245, 0) 100%);
}

.stat {
  background: radial-gradient(
      232.36% 214.3% at 124.11% -92.78%,
      rgba(218, 227, 242, 0.2) 0%,
      rgba(102, 122, 155, 0) 100%
    ),
    rgba(28, 32, 41, 0.8);
}

.tooltip {
  background: radial-gradient(232.36% 214.3% at 124.11% -92.78%, rgba(218, 227, 242, 0.2) 0%, rgba(53, 84, 137, 0) 100%),
    rgba(3, 10, 26, 0.9);
}

.tooltip-dark {
  background: radial-gradient(
      232.36% 214.3% at 124.11% -92.78%,
      rgba(218, 227, 242, 0.2) 0%,
      rgba(102, 122, 155, 0) 100%
    ),
    #000;
}

.fade-out {
  mask-image: url('/shared/video-mask.svg');
  mask-mode: alpha;
  -webkit-mask-image: url('/shared/video-mask.svg');
  -webkit-mask-mode: alpha;
}

/* For Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 4px; /* width of the entire scrollbar */
  height: 4px;
}

::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

ul::-webkit-scrollbar-thumb {
  background: #444; /* color of the scroll thumb */
  border-radius: 6px;
  background-clip: padding-box;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
}

*::-webkit-scrollbar-thumb {
  background: #444; /* color of the scroll thumb */
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* color on hover */
}

.glow-box-bright {
  position: relative;
}

.glow-box-bright::before {
  content: '';
  position: absolute;
  inset: 0.75rem;
  border-radius: inherit;
  background: linear-gradient(to right, #88b4f5, #88b4f560, #88b4f5);
  opacity: 0.8;
  filter: blur(1.25rem);
  pointer-events: none;
}

.glow-shadow {
  box-shadow: 0px 0px 8px 0px rgba(136, 180, 245, 0.5);
}

@layer components {
  .badge-border-shadow--ethena--active {
    background:
      linear-gradient(black, black) padding-box padding-box,
      linear-gradient(to top, #88b4f5, transparent 90%) border-box;
    border-radius: 20px;
    border: 1px solid #0000;
  }
  .badge-border-shadow--ethena {
    background:
      linear-gradient(black, black) padding-box padding-box,
      linear-gradient(to top, #767676, #323232 100%) border-box;
    border-radius: 20px;
    border: 1px solid #0000;
    transition: background-position 0.3s;
    position: relative;
  }

  .badge-border-shadow--ethena::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80%;
    background: radial-gradient(45.93% 40.26% at 50% 100%, #88b4f5cc 0, #88b4f500 100%);
    opacity: 0;
    border-radius: 20px;
    transition: opacity 0.3s;
  }

  .badge-border-shadow--ethena:hover::before {
    opacity: 0.5;
  }

  .active-badge-glow::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80%;
    background: radial-gradient(45.93% 40.26% at 50% 100%, #88b4f5cc 0, #88b4f500 100%);
    border-radius: 20px;
  }

  .badge-border-shadow--ethereal {
    background:
      linear-gradient(#191d23, #191d23) padding-box,
      linear-gradient(to top, #a6d2ff, #374351) border-box;
    border: 1px solid transparent;
  }
}
.text-glow-red {
  text-shadow: 0px 0px 10px rgba(255, 0, 0, 0.8);
}

.modal {
  background: radial-gradient(232.36% 214.3% at 124.11% -92.78%, rgba(218, 227, 242, 0.2) 0%, #000), #000;
}

@property --placeholder-opacity {
  syntax: '<number>';
  initial-value: 0.75;
  inherits: true;
}

@keyframes placeholder-pulse {
  0%,
  100% {
    --placeholder-opacity: 0.75;
  }
  50% {
    --placeholder-opacity: 0.25;
  }
}
